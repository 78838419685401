// ----- Imports ----- //
import { trackComponentEvents } from '../tracking/trackingOphan';
// ----- Types ------ //
const DigitalPack = 'DigitalPack';
const PremiumTier = 'PremiumTier';
const DailyEdition = 'DailyEdition';
const GuardianWeekly = 'GuardianWeekly';
const Paper = 'Paper';
const PaperAndDigital = 'PaperAndDigital';
export const subscriptionProductTypes = [
    DigitalPack,
    PremiumTier,
    DailyEdition,
    GuardianWeekly,
    Paper,
    PaperAndDigital,
];
export const paperProductTypes = [Paper, PaperAndDigital];
// ----- Functions ----- //
const isPhysicalProduct = (product) => {
    switch (product) {
        case Paper:
        case PaperAndDigital:
        case GuardianWeekly:
            return true;
        default:
            return false;
    }
};
function fixDecimals(number) {
    if (Number.isInteger(number)) {
        return number.toString();
    }
    return number.toFixed(2);
}
// ----- Ophan Tracking ----- //
function ophanProductFromSubscriptionProduct(product) {
    switch (product) {
        case 'DigitalPack':
        case 'PremiumTier':
        case 'DailyEdition':
            return 'DIGITAL_SUBSCRIPTION';
        case 'GuardianWeekly':
        case 'Paper':
        case 'PaperAndDigital':
        default:
            return 'PRINT_SUBSCRIPTION';
    }
}
const sendTrackingEvent = (trackingProperties) => {
    const { id, product, abTest, componentType, action } = trackingProperties;
    const componentEvent = {
        component: {
            componentType,
            id,
            products: product ? [ophanProductFromSubscriptionProduct(product)] : [],
        },
        action,
        id,
        ...(abTest
            ? {
                abTest,
            }
            : {}),
    };
    trackComponentEvents(componentEvent);
};
const sendTrackingEventsOnClick = (trackingProperties) => () => {
    sendTrackingEvent({ ...trackingProperties, action: 'CLICK' });
};
const sendTrackingEventsOnView = (trackingProperties) => () => {
    sendTrackingEvent({ ...trackingProperties, action: 'VIEW' });
};
// ----- Exports ----- //
export { sendTrackingEventsOnClick, sendTrackingEventsOnView, fixDecimals, DigitalPack, PaperAndDigital, Paper, GuardianWeekly, isPhysicalProduct, };
