import { countryGroups } from '../internationalisation/countryGroup';
import { addQueryParamsToURL, getAllQueryParams, getOrigin, isProd, } from './url';
import 'helpers/types/option';
const routes = {
    recurringContribCheckout: '/contribute/recurring',
    recurringContribCreate: '/contribute/recurring/create',
    recurringContribPending: '/contribute/recurring/pending',
    contributionsSendMarketing: '/contribute/send-marketing',
    getUserType: '/identity/get-user-type',
    contributionsMarketingConfirm: '/contribute/marketing-confirm',
    payPalSetupPayment: '/paypal/setup-payment',
    payPalCreateAgreement: '/paypal/create-agreement',
    payPalOneClickCheckout: '/paypal/one-click-checkout',
    directDebitCheckAccount: '/direct-debit/check-account',
    payPalRestReturnURL: '/paypal/rest/return',
    subscriptionCreate: '/subscribe/create',
    subscriptionsLanding: '/subscribe',
    checkout: '/checkout',
    digitalSubscriptionLanding: '/subscribe/digitaledition',
    paperSubscriptionLanding: '/subscribe/paper',
    paperSubscriptionProductChoices: '/subscribe/paper#HomeDelivery',
    paperSubscriptionDeliveryProductChoices: '/subscribe/paper/delivery#HomeDelivery',
    guardianWeeklySubscriptionLanding: '/subscribe/weekly',
    guardianWeeklySubscriptionLandingGift: '/subscribe/weekly/gift',
    guardianWeeklyStudentUK: 'https://connect.studentbeans.com/v4/hosted/the-guardian-weekly/uk',
    guardianWeeklyStudentAU: 'https://connect.studentbeans.com/v4/hosted/the-guardian-weekly/au',
    postcodeLookup: '/postcode-lookup',
    createSignInUrl: '/identity/signin-url',
    stripeSetupIntentRecaptcha: '/stripe/create-setup-intent/recaptcha',
};
const createOneOffReminderEndpoint = isProd()
    ? 'https://support.theguardian.com/reminders/create/one-off'
    : 'https://support.code.dev-theguardian.com/reminders/create/one-off';
const createRecurringReminderEndpoint = isProd()
    ? 'https://support.theguardian.com/reminders/create/recurring'
    : 'https://support.code.dev-theguardian.com/reminders/create/recurring';
const countryPath = (countryGroupId) => countryGroups[countryGroupId].supportInternationalisationId;
function postcodeLookupUrl(postcode) {
    return `${getOrigin() + routes.postcodeLookup}/${postcode}`;
}
function paperSubsUrl(paperFulfulmentOption, promoCode) {
    const baseURL = [getOrigin(), 'uk/subscribe/paper'].join('/');
    const queryParams = [
        ...getAllQueryParams(),
        ...(promoCode ? [['promoCode', promoCode]] : []),
    ];
    const queryParamsString = queryParams
        .map((keyValuePair) => keyValuePair.join('='))
        .join('&');
    const hash = paperFulfulmentOption ? `#${paperFulfulmentOption}` : '';
    if (queryParamsString) {
        return `${baseURL}?${queryParamsString}${hash}`;
    }
    return baseURL;
}
function digitalSubscriptionLanding(countryGroupId, billingPeriod) {
    const routeDigitalSubscription = billingPeriod
        ? `${routes.checkout}?product=DigitalSubscription&ratePlan=${billingPeriod}`
        : routes.digitalSubscriptionLanding;
    return `${getOrigin()}/${countryPath(countryGroupId)}${routeDigitalSubscription}`;
}
function guardianWeeklyLanding(countryGroupId, gift) {
    return `${getOrigin()}/${countryPath(countryGroupId)}${gift
        ? routes.guardianWeeklySubscriptionLandingGift
        : routes.guardianWeeklySubscriptionLanding}`;
}
const promotionTermsUrl = (promoCode) => `${getOrigin()}/p/${promoCode}/terms`;
function paperCheckoutUrl(fulfilmentOption, productOptions, abParticipations, promoCode) {
    if (abParticipations.newspaperGenericCheckout === 'variant') {
        const url = `${getOrigin()}/uk/checkout`;
        return addQueryParamsToURL(url, {
            promoCode,
            product: fulfilmentOption === 'Collection'
                ? 'SubscriptionCard'
                : fulfilmentOption,
            ratePlan: productOptions,
        });
    }
    const url = `${getOrigin()}/subscribe/paper/checkout`;
    return addQueryParamsToURL(url, {
        promoCode,
        fulfilment: fulfilmentOption,
        product: productOptions,
    });
}
// If the user cancels before completing the payment flow, send them back to the contribute page.
function payPalCancelUrl(cgId) {
    return `${getOrigin()}/${countryPath(cgId)}/contribute`;
}
function payPalReturnUrl(cgId, email, route = '/paypal/rest/return') {
    return `${getOrigin()}/${countryPath(cgId)}${route}?email=${encodeURIComponent(email)}`;
}
// ----- Exports ----- //
export { routes, createOneOffReminderEndpoint, createRecurringReminderEndpoint, postcodeLookupUrl, payPalCancelUrl, payPalReturnUrl, paperSubsUrl, paperCheckoutUrl, digitalSubscriptionLanding, guardianWeeklyLanding, promotionTermsUrl, };
