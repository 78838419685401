const Stripe = 'Stripe';
const PayPal = 'PayPal';
const DirectDebit = 'DirectDebit';
const StripeHostedCheckout = 'StripeHostedCheckout';
const Sepa = 'Sepa';
const None = 'None';
const Success = 'success';
const Pending = 'pending';
export const recaptchaRequiredPaymentMethods = [
    DirectDebit,
    Stripe,
];
const paymentMethods = [
    Stripe,
    PayPal,
    DirectDebit,
    StripeHostedCheckout,
    Sepa,
    None,
];
export const isPaymentMethod = (paymentMethod) => {
    return (typeof paymentMethod === 'string' && paymentMethods.includes(paymentMethod));
};
export function toPaymentMethodSwitchNaming(paymentMethod) {
    switch (paymentMethod) {
        case PayPal:
            return 'payPal';
        case Stripe:
            return 'stripe';
        case DirectDebit:
            return 'directDebit';
        case StripeHostedCheckout:
            return 'stripeHostedCheckout';
        case Sepa:
            return 'sepa';
        case None:
            return null;
    }
}
export { Stripe, PayPal, DirectDebit, StripeHostedCheckout, Sepa };
